import FoodAllergiesTemplate from '@/modules/questionnaire/components/steps/common/medical-background/food-allergies/FoodAllergiesTemplate';

import { FOOD_ALLERGY, OTHER_VALUE_BINDINGS } from '@/modules/questionnaire/api/constants';

export default {
  title: 'Steps/MedicalBackground/FoodAllergies/FoodAllergiesTemplate',
  component: FoodAllergiesTemplate
};

const createStory = props => () => ({
  components: { FoodAllergiesTemplate },
  storyProps: props,
  wrapperStyles: {
    display: 'flex',
    justifyContent: 'center'
  },
  template:
    '<div :style="$options.wrapperStyles"><food-allergies-template v-bind="$options.storyProps" /></div>'
});

export const maxAllergiesSelected = createStory({
  foodAllergies: [
    FOOD_ALLERGY.TARTRAZINE,
    FOOD_ALLERGY.WHEAT,
    FOOD_ALLERGY.EGGS,
    FOOD_ALLERGY.TREE_NUTS
  ],
  otherFoodAllergies: ''
});

export const unselected = createStory({
  foodAllergies: [],
  otherFoodAllergies: ''
});

export const otherAllergiesSelected = createStory({
  foodAllergies: [OTHER_VALUE_BINDINGS.value],
  otherFoodAllergies: 'other allergies'
});
